<template>
	<main id="container" class="page-container information-history bg-wave4">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="행사연혁" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="info-history-header">
					<div class="info-history-header__title">
						<span>지난 동행축제의 발자취입니다.</span>
						<span class="prima">보고 싶은 회차를 선택하세요.</span>
					</div>
					<div class="info-history-header__select dropdown-style">
						<drop-down ref="dropDown1" id="dropDown1" init="0" :placeholder="campn.campnNm">
							<div v-for="(campn, idx1) in items" :key="idx1" :class="campn.campnId == item.campnId ? 'dropdown-item is-active' : 'dropdown-item'">
								<button type="button" @click.prevent="clickDropdown1($event, campn.campnId)" class="text-link">
									<span class="dropdown-text">{{ campn.campnNm }}</span>
								</button>
							</div>
						</drop-down>
					</div>
				</div>
				<div class="info-history-explain">
					<span class="info-history-explain__title">{{ item.campnNm }}</span>
					<span class="info-history-explain__title-large">{{ item.campnTitCn }}</span>
					<p class="info-history-explain__desc" v-html="item.campnDesc"></p>
				</div>
				<div class="info-history-guide">
					<span class="info-history-guide__title"> {{ item.campnMainDesc }}</span>
					<div class="info-history-guide__event">
						<div class="event-content">
							<div class="event-content-img">
								<img src="~@/assets/img/main/event_icon01.png" alt="행사기간" />
							</div>
							<div class="event-content-desc">
								<span class="event-content-desc__title">행사 기간</span>
								<div class="event-content-desc__period">
									<span>{{ item.strtDt | dateKorConvert }} 부터 ~</span>
									<span>{{ item.endDt | dateKorConvert }} 까지</span>
								</div>
							</div>
						</div>
						<div class="event-content">
							<div class="event-content-img">
								<img src="~@/assets/img/main/event_icon02.png" alt="행사내용" />
							</div>
							<div class="event-content-desc">
								<span class="event-content-desc__title">행사 내용</span>
								<p class="event-content-desc__text">{{ item.campnPrimCn }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="infohistory-image-container">
				<ul class="infohistory-image-list">
					<li class="infohistory-image-list-li" v-for="(item, idx) in campnGlryDtlItems" :key="idx">
						<a href="" class="infohistory-image-list-item" v-bind:class="{ video: item.video }" @click.prevent="onClickShowImg(campnGlryDtlItems, idx)">
							<img :src="item.imgFileUrl" :alt="item.imgFileNm" />
							<div class="video-link">
								<i class="icon">
									<img src="~@/assets/img/information/arrow-icon-white.png" alt="" />
								</i>
							</div>
						</a>
					</li>
				</ul>
			</div>

			<campn-gallery-swiper ref="gallerySwiper"></campn-gallery-swiper>

			<div class="container">
				<div class="info-history-products-title">
					<span>동행제품</span>
				</div>
				<div class="category-items">
					<ul class="category-items-list">
						<li class="category-items-list-li" v-for="item in ctgrItems" :key="item.dcd">
							<button type="button" :class="item.dcd == ctgrDcd ? 'category-item is-active' : 'category-item'" @click="searchManprdList(item.dcd)">
								<!-- <div class="category-item is-active"> -->
								<div class="category-item-img">
									<i class="icon">
										<img :src="require(`@/assets/img/category/${item.img}`)" alt="" />
									</i>
								</div>
								<span class="item-title">{{ item.dcdVal }}</span>
							</button>
						</li>
					</ul>
				</div>
				<div class="products-list">
					<products-item v-for="(item, idx) in manprdList" :key="item.manprdId" :id="item.id" :item="item" type="" :like="getProductLikeRate" :rate="getProductLikeRate" parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
				</div>
			</div>
		</div>
		<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :like="getProductLikeRate" :rate="getProductLikeRate" :item="modalItem" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt" />
		<div class="pagination-wrap">
			<portal-pagination page-name="Category" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getManprdList="getManprdList"></portal-pagination>
		</div>
	</main>
</template>

<script>
import PortalPagination from '@/components/PortalPaginationRoute';
import PageHeader from '@/components/content/PageHeader';
import ProductsModal from '@/components/content/ProductsModal';
import ProductsItem from '@/components/content/ProductsItem';
import CampnGallerySwiper from '@/views/pages/ko/information/GallerySwiper.vue'; // 탭 - 갤러리 스와이퍼
import DropDown from '@/components/common/DropDown';
import { getCheckItems, getItems, lengthCheck, dateToDateFormat, tryResCallback, timestampToDateFormat } from '@/assets/js/utils';
import { ACT_GET_CAMPN_INFO_LIST, ACT_GET_CAMPN_GLRY_LIST, ACT_GET_COMMON_CODE_LIST, ACT_GET_CAMPN_MANPRD_LIST } from '@/store/_act_consts';
import { mapGetters } from 'vuex';

export default {
	name: 'CampnHistory',
	components: { PortalPagination, PageHeader, ProductsItem, ProductsModal, CampnGallerySwiper, DropDown },
	data: () => ({
		//연혁정보
		selectCampnId: 0,
		ctgrIndex: 0,
		tmpexbClsDcd: 0,
		tmpexbClsDcdList: [],
		items: [],
		item: {},
		tmpexbItems: [],
		campnGlryDtlMain: {},
		campnGlryDtlItems: [],
		//동행제품
		pagingMethodName: 'getManprdList',
		pageNo: 1,
		pageSize: 10,
		pageListSize: 10,
		totalCount: 0,
		searchName: '',
		visibleModal: false,
		ctgrDcd: '',
		ctgrItems: [],
		modalId: 0,
		modalType: '',
		manprdList: [],
		manprdAllList: [],
		manprdFilterList: [],
	}),
	computed: {
		...mapGetters('common', ['commonCode', 'campn']),
		...mapGetters('auth', ['session', 'isAuth']),
		dateConvert() {
			return (item, format) => {
				return dateToDateFormat(new Date(item), format);
			};
		},

		campnId() {
			return this.campn.campnId;
		},
		modalItem() {
			if (this.modalId > 0) return this.manprdAllList.find((i) => i.id == this.modalId);

			return null;
		},
		queries() {
			const result = {};
			result.searchName = this.searchName;
			result.ctgrDcd = this.ctgrDcd;
			return result;
		},
		getProductLikeRate() {
			const campnStrtDt 	= this.dateConvert(this.campn.strtDt,'yyyyMMdd');
			const campnEndDt 	= this.dateConvert(this.campn.endDt,'yyyyMMdd');
			const nowDate 		= this.dateConvert(new Date(),'yyyyMMdd');
			if(campnStrtDt >= nowDate || campnEndDt <= nowDate) return false;
			return this.campn.campnId == this.selectCampnId ? true : false;
		}
	},
	filters: {
		dateKorConvert(val) {
			const date = new Date(val);
			return `${date.getFullYear()}년  ${('0' + (date.getMonth() + 1)).slice(-2)}월 ${('0' + date.getDate()).slice(-2)}일`;
		},
	},
	created() {
		this.initCampnList(this.campn.campnId);
	},
	mounted() {},
	methods: {
		initCampnList(campnId) {
			this.selectCampnId = campnId;
			this.getCampnList();
			this.getCampnGlryDtlList();
			this.getCtgrList();
			this.getManprdAllList();
		},
		changeCampId(val) {
			this.initCampnList(val);
		},
		async getCampnList() {
			await this.$store.dispatch(`information/${ACT_GET_CAMPN_INFO_LIST}`, { campnId: this.campn.campnId }).then((res) => {
				this.items = getCheckItems(res).filter((x) => x.expsrYn === 'Y');
				
				if(typeof this.selectCampnId === "undefined" || this.selectCampnId === 0) this.selectCampnId = this.items[0].campnId;
				this.item = this.items.filter((x) => {
					return x.campnId == this.selectCampnId;
				})[0];
			});
		},
		async getCampnGlryDtlList() {
			await this.$store
				.dispatch(`information/${ACT_GET_CAMPN_GLRY_LIST}`, { campnId: this.selectCampnId })
				.then((res) => {
					const items = getCheckItems(res);
					this.campnGlryDtlMain = items.find((dtl) => dtl.rprsImgYn === 'Y');
					this.campnGlryDtlItems = items;
					this.setGlryParams();
				})
				.catch(console.error);
		},
		setGlryParams() {
			this.campnGlryDtlItems.forEach((item) => {
				item.video = item.videoFileUrl !== '' ? true : false;
			});
		},
		async getCtgrList() {
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: '1501', masterYn: 'N' })
				.then((res) => {
					if (lengthCheck(res)) {
						const items = getItems(res).map((i) => {
							let item = {};
							switch (i.dcd) {
								case '1501001':
									item = { ...i, img: 'list_item02.png', title: '뷰티' };
									break;
								case '1501002':
									item = { ...i, img: 'list_item03.png', title: '생활' };
									break;
								case '1501003':
									item = { ...i, img: 'list_item04.png', title: '식품' };
									break;
								case '1501004':
									item = { ...i, img: 'list_item05.png', title: '전자기기' };
									break;
								case '1501005':
									item = { ...i, img: 'list_item06.png', title: '가구·조명' };
									break;
								case '1501006':
									item = { ...i, img: 'list_item07.png', title: '주방' };
									break;
								case '1501007':
									item = { ...i, img: 'list_item08.png', title: '패션·잡화' };
									break;
								default:
									break;
							}

							return item;
						});
						this.ctgrItems = [];
						this.ctgrItems = this.ctgrItems.concat(
							{
								dcd: '',
								dcdVal: '전체',
								img: 'list_item01.png',
								title: '전체',
							},
							items
						);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},

		onClickShowImg(glryDtlList = [], idx = 0) {
			this.$refs.gallerySwiper.onClickShowImg(this.campnGlryDtlItems, idx);
		},
		onClickHide() {
			this.$refs.gallerySwiper.onClickHide();
		},

		getManprdAllList() {
			this.pageNo = 1;
			this.manprdList = [];

			// api 호출
			this.$store
				.dispatch(`manprd/${ACT_GET_CAMPN_MANPRD_LIST}`, {
					campnId: this.selectCampnId,
					manprdNm: '',
					ctgrDcd: '',
					sortCd: '',
					pageNo: 1,
					pageSize: 1000,
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getManprdAllList, this.setManprdAllList);
				})
				.catch((e) => {
					console.error(e);
				});
		}, 
		setManprdAllList(res) {
			if (lengthCheck(res)) {
				this.manprdFilterList = this.manprdAllList = getCheckItems(res).map((i) => {
					return {
						id: i.manprdId,
						image: i.manprdImgFileUrl,
						company: i.brndNm,
						title: i.manprdNm,
						price: i.manprdPrc,
						rate: i.socreAvg,
						rateCount: i.scoreCnt,
						like: i.favrYn == 'Y',
						likeCount: i.favrCnt,
						myRate: i.myScore,
						desc: i.manprdDesc,
						siteUrl: i.ntslSiteUrl,
						isEng : false,
						useCampnDurYn: i.useCampnDurYn == 'Y',
						ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
						ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd'),
						ctgrDcd: i.ctgrDcd,
					};
				});
				this.totalCount = res.paging.totalCount;
				this.searchManprdList(this.ctgrDcd);
			}
			
			//setPaging(this, res);
		},
		getManprdList(pageNo) {
			this.pageNo = pageNo;
			this.manprdList = [];
			this.manprdList = this.manprdFilterList.filter((m, idx) => idx >= (this.pageNo - 1) * this.pageSize && idx < this.pageNo * this.pageSize);
		},
		searchManprdList(dcd) {
			this.ctgrDcd = dcd;
			let searchManprdList = [];

			if(this.searchName) {
				searchManprdList = this.manprdAllList.filter((m) => m.title.indexOf(this.searchName) > -1);
			} 
			else {
				searchManprdList = this.manprdAllList ;
			}

			if(this.ctgrDcd) this.manprdFilterList = searchManprdList.filter((m) => m.ctgrDcd == this.ctgrDcd);
			else this.manprdFilterList = searchManprdList;

			this.totalCount = this.manprdFilterList.length;
			this.getManprdList(1);
		},
		// 찜하기
		handleClickLike(idx, type) {
			this.getManprdAllList();
			// const item = this.manprdList.find(i => i.id == idx);
			// this.setFavr(item);
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.getManprdAllList();
			// if(type == "F") this.setFavr(this.modalItem);
			// else this.setStar({ ...this.modalItem, myRate: score});
		},

		clickDropdown1(event, val) {
			if (this.item.campnId != val) this.changeCampId(val);
			this.$refs.dropDown1.selectDropdownItem(event);
		},
	},
};
</script>
