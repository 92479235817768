<template>
	<transition name="fade" mode="out-in">
		<div class="lightbox-container" v-show="visible">
			<div class="lightbox">
				<div class="lightbox-header">
					<button type="button" class="button-lightbox-close" @click.prevent="onClickHide()"><i class="icon-pop-close"></i><span class="sr-only">닫기</span></button>
				</div>
				<div class="lightbox-body">
					<swiper ref="swiperLightbox" class="swiper" :options="swiperOption">
						<swiper-slide v-for="(glryDtl, idx2) in targetGlryDtlList" :key="idx2">
							<div v-if="!!glryDtl.videoFileUrl" class="vod-wrap" id="vodWrap">
								<video id="videoTag" loop autoplay muted preload="none" style="width: 100%" alt="">
									<source :src="glryDtl.videoFileUrl" type="video/mp4" />
								</video>
							</div>
							<div v-else class="lightbox-img">
								<img :src="glryDtl.imgFileUrl" :alt="glryDtl.imgFileDesc" />
							</div>
							<span class="lightbox-text">{{ glryDtl.imgFileDesc }}</span>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev">
							<i class="icon"><icon name="icon-swiper-arrow"></icon></i>
						</div>
						<div class="swiper-button-next" slot="button-next">
							<i class="icon"><icon name="icon-swiper-arrow"></icon></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Icon from '@/components/common/Icon';
export default {
	name: 'CampnGallerySwiper',
	components: { Icon },
	props: {},
	watch: {},
	computed: {},
	data: () => ({
		visible: false,
		swiperOption: {
			loop: false,
			effect: 'fade',
			initialSlide: 0,
			autoHeight: true,
			notNextTick: true,
			slidesPerView: 1,
			spaceBetween: 10,
			touchRatio: 0.2,
			navigation: {
				nextEl: '.lightbox .swiper-button-next',
				prevEl: '.lightbox .swiper-button-prev',
			},
			preloadImages: false,
			on: {
				transitionStart: () => {
					const videos = document.querySelectorAll('video');
					Array.prototype.forEach.call(videos, (video) => {
						video.pause();
						video.currentTime = 0;
					});
				},
				transitionEnd: function () {
					// console.log(this);
					const activeIndex = this.activeIndex;
					const activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
					if (!!activeSlide) {
						const activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
						if (!!activeSlideVideo) activeSlideVideo.play();
					}
				},
			},
		},
		targetGlryDtlList: [],
	}),
	created() {},
	mounted() {
		document.addEventListener('keyup', this.onPressEsc);
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.onPressEsc);
	},
	methods: {
		// event ===============================================================
		onClickShowImg(glryDtlList = [], idx = 0) {
			this.visible = true;
			this.setTargetGlryDtlList(glryDtlList);
			const swiperLightbox = this.$refs.swiperLightbox.$swiper;

			this.$nextTick(() => {
				swiperLightbox.slideTo(idx);
				setTimeout(() => {
					swiperLightbox.update();
				}, 100);
				document.querySelector('html').style.overflow = 'hidden';
			});
		},
		onClickHide() {
			this.closeSwiper();
		},
		onPressEsc(e) {
			if (e.key === 'Escape' || e.keyCode === 27) {
				this.closeSwiper();
			}
		},

		// custom fn ===============================================================

		closeSwiper() {
			this.visible = false;
			this.setTargetGlryDtlList([]);
			document.querySelector('html').style.overflow = 'initial';
		},
		setTargetGlryDtlList(dtlList = []) {
			this.targetGlryDtlList = dtlList;
		},
	},
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.icon-pop-close {
	display: flex;
	position: relative;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-content: center;
	&::after,
	&::before {
		content: '';
		position: absolute;
		width: 1.5px;
		height: 29px;
		top: 50%;
		left: 50%;
		background-color: #fff;
	}
	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}
.lightbox {
	// display: flex;
	position: absolute;
	width: 80%;
	max-width: 1200px;
	max-height: 100vh;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	// position: relative;
	// flex-direction: column;
	// justify-content: center;
	@include mobile {
		width: 100%;
		padding: 15px;
	}
	.vod-wrap {
		width: 100%;
	}
	.button-lightbox-close {
		display: flex;
		width: 24px;
		height: 24px;
	}
	&-header {
		display: flex;
		padding-bottom: 20px;
		align-items: center;
		justify-content: flex-end;
		flex: 0 0 24px;
	}
	&-body {
		display: block;
		// overflow: hidden;
		flex: 1 1 calc(100% - 100px);
	}
	&-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-img {
		// display: flex;
		// width: 100%;
		// height: 100%;
		// max-height: calc(100vh - 150px);
		overflow: hidden;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		img {
			width: 100%;
			height: initial;
			max-height: calc(100vh - 150px);
			object-fit: contain;
		}
	}
	&-text {
		display: block;
		max-height: 41px;
		margin-top: 24px;
		font-size: 16px;
		line-height: 1.25;
		color: #fff;
		@include text-ellipsis-2;
	}
	.swiper-slide {
		display: flex;
		opacity: 0;
		visibility: hidden;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		&-active {
			opacity: 1;
			visibility: visible;
		}
	}

	.swiper-buttons-container {
		.swiper-button-next,
		.swiper-button-prev {
			@include mobile {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.7);
				transform: scale(1);
			}
			&::after {
				border-color: #fff;
				@include mobile {
					top: 50%;
					left: 50%;
					width: 13px;
					height: 13px;
					border-color: #000;
					border-width: 1px;
				}
			}
		}
		.swiper-button-prev {
			left: -45px;
			@include mobile {
				left: 25px;
				&::after {
					transform: translate(-30%, -50%) rotate(225deg);
				}
			}
		}
		.swiper-button-next {
			right: -45px;
			@include mobile {
				right: 25px;
				&::after {
					transform: translate(-70%, -50%) rotate(45deg);
				}
			}
		}
	}
}
</style>
